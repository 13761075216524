


var bnb = document.getElementById("binancecoin");
var eth = document.getElementById("ethereum");
var fan = document.getElementById("fantom");
var tet = document.getElementById("tether");
var gno = document.getElementById("gnosis");
var trx = document.getElementById("tron");
var sol = document.getElementById("solana");
var evmos = document.getElementById("evmos");
var aurora = document.getElementById("aurora");
var one = document.getElementById("harmony");
var movr = document.getElementById("moonriver");
var conflux = document.getElementById("conflux");

var liveprice = {
    "async": true,
    "scroosDomain": true,
    "url": "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin%2Cethereum%2Cfantom%2Ctether%2Cgnosis%2Ctron%2Csolana%2Cconflux-token%2Cevmos%2Caurora%2Charmony%2Cmoonriver&vs_currencies=usd",
    
    "method": "GET",
    "headers": {}
}

$.ajax(liveprice).done(function (response){
    let conflux_token = response['conflux-token'];
    bnb.innerHTML = response.binancecoin.usd;
    eth.innerHTML = response.ethereum.usd;
    tet.innerHTML = response.tether.usd;
    fan.innerHTML = response.fantom.usd;
    gno.innerHTML = response.gnosis.usd;
    trx.innerHTML = response.tron.usd;
    sol.innerHTML = response.solana.usd;
    evmos.innerHTML = response.evmos.usd;
    aurora.innerHTML = response.aurora.usd;
    one.innerHTML = response.harmony.usd;
    movr.innerHTML = response.moonriver.usd;
    conflux.innerHTML = conflux_token.usd;
});





var swiper = new Swiper("#Swiper1", {
    spaceBetween: 24,
    loop: false,
    autoplay: false,
    speed: 1000,
    slidesPerView: 4,
    navigation: {
        nextEl: ".swiper-button-next1",
        prevEl: ".swiper-button-prev1",
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
        },
        575: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        992: {
            slidesPerView: 4,
        },
    },
});